<template>
  <div class="die-roll">
    <span v-if="!minimal">({{ dieStr }})</span>
    <button @click="roll">{{ lastRoll || placeholder || "Roll" }}</button>
  </div>
</template>

<script>
export default {
  name: "DieRoll",
  props: {
    dieStr: String,
    default: Number,
    placeholder: String,
    minimal: Boolean
  },
  data() {
    return {
      lastRoll: this.default
    }
  },
  computed: {
    die() {
      let dice, numDice, dieType, modifier;
      [dice, modifier] = this.dieStr.split('+');
      if (modifier === undefined) {
        [dice, modifier] = this.dieStr.split('-');
        modifier = modifier || 0;
        modifier = 0 - modifier;
      }

      [numDice, dieType] = dice.split('d');

      return {
        numDice: Number(numDice),
        dieType: Number(dieType),
        modifier: Number(modifier)
      };
    }
  },
  methods: {
    roll() {
      let total = this.die.modifier;
      for (let i = 0; i < this.die.numDice; i++) {
        total += Math.ceil(Math.random() * this.die.dieType);
      }

      this.lastRoll = total;
    }
  }
}
</script>

<style scoped>
  .die-roll {
    display: inline-block;
  }

  .die-roll button {
    margin: 0 0.25rem;
    border: none;
    border-bottom: 1px dotted black;
    padding: 0 0.5rem;

    background: none;
    color: black;

    font-size: 1em;
  }

  .die-roll button:hover {
    border-bottom-color: #aaa;
    color: #555;
  }

  .result {
    margin: 0.25rem;
  }
</style>