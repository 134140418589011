<template>
  <div class="action">
    <strong>{{ action.name }}.</strong>
    {{ action.desc }}<br/>
    <em>Attack (+{{ action.attack_bonus }}): </em>
    <die-roll minimal :die-str="'1d20+' + action.attack_bonus"/>
    <div v-for="damage in action.damage" :key="damage.damage_type.index">
      <em>{{ damage.damage_type.name }}: </em>
      <die-roll :die-str="damage.damage_dice"/>
    </div>
  </div>
</template>

<script>
import DieRoll from "@/components/DieRoll";
export default {
  name: "Action",
  components: {DieRoll},
  props: {
    action: Object
  }
}
</script>

<style scoped>
  .action {
    margin: 0.8rem 0;
  }
</style>