<template>
  <div>
    <div class="monster" v-if="monster">
      <!-- <h5>TODO: Vulnerabilities, Resistances, Immunities, Senses, Languages, Abilities</h5> -->
      <h2>
        {{ monster.name }}
        <button @click="add">Add</button>
      </h2>
      <div class="error">{{ capturedError }}</div>
      <em>{{ monster.size }} {{ monster.type }} ({{ monster.subtype }}). {{ monster.alignment }}.</em>
      <div class="stats">
        <span><strong>AC: </strong>{{ monster.armor_class }}</span>
        <span><strong>HP: </strong>{{ monster.hit_points }} ({{ monster.hit_dice }})</span>
        <span>
          <strong>Speed: </strong>
          <span v-for="(distance, type) in monster.speed" :key="type">{{ distance }} ({{ type }}) </span>
        </span>
      </div>
      <div class="stats">
        <span><strong>XP: </strong>{{ monster.xp }}</span>
        <span><strong>Challenge Rating: </strong>{{ monster.challenge_rating }}</span>
      </div>
      <div class="abilities">
        <ability name="STR" :score="monster.strength"/>
        <ability name="DEX" :score="monster.dexterity"/>
        <ability name="CON" :score="monster.constitution"/>
        <ability name="INT" :score="monster.intelligence"/>
        <ability name="WIS" :score="monster.wisdom"/>
        <ability name="CHA" :score="monster.charisma"/>
      </div>
      <strong>Proficiencies: </strong>
      <span class="proficiency" v-for="pro in monster.proficiencies" :key="pro.proficiency.index">
        {{ pro.proficiency.name }} (+{{ pro.value }}, <die-roll minimal :die-str="'1d20+' + pro.value"/>). </span>
      <h3>Actions</h3>
      <action v-for="action in monster.actions" :key="action.name" :action="action"/>
    </div>
    <div v-else>
      Loading {{ monsterName }}...
    </div>
  </div>
</template>

<script>
import Action from "@/components/Action";
import DieRoll from "@/components/DieRoll";
import Ability from "@/components/Ability";
import axios from "axios";

export default {
  name: "Monster",
  components: {Ability, DieRoll, Action},
  props: {
    monsterName: String,
  },
  data() {
    return {
      monster: null,
      capturedError: null
    }
  },
  methods: {
    add() {
      this.$emit('add', this.monster);
    }
  },
  async mounted() {
    const res = await axios.get('https://www.dnd5eapi.co/api/monsters/' + this.monsterName);
    this.monster = res.data;
  },
  errorCaptured(err) {
    this.capturedError = err;
  }
}
</script>

<style scoped>
h2, h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0;
}

.monster {
  margin-bottom: 0.25rem;
  border: 1px solid black;
  padding: 0.5rem 1rem;
}

.error {
  color: red;
}

.stats {
  display: flex;
  max-width: 25rem;

  margin: 0.5rem 0;
}

.stats > * {
  flex: auto;
}

.abilities {
  margin: 0.5rem 0;
  border-top: 1px dotted #555;
  border-bottom: 1px dotted #555;
  padding: 0.5rem;
}
</style>