<template>
  <div class="ability">
    <strong>{{ name }}</strong>
    <div class="modifier">{{ score }} ({{ modifier }})</div>
    <die-roll minimal :die-str="'1d20' + modifier"></die-roll>
  </div>
</template>

<script>
import DieRoll from "@/components/DieRoll";
export default {
  name: "Ability",
  components: {DieRoll},
  props: {
    name: String,
    score: Number
  },
  computed: {
    modifier() {
      const mod = Math.floor(this.score / 2) - 5;

      if (mod < 0) {
        return String(mod);
      }

      return "+" + mod;
    }
  }
}
</script>

<style scoped>
  .ability {
    display: inline-block;
    text-align: center;

    margin: 0 0.5rem;
  }
</style>