<template>
  <div class="encounter">
    <div class="monsters">
      <autocomplete
          :search="searchMonsters"
          :debounce-time="100"
          @keypress.enter="addToMonsters"
      />
      <monster
          v-for="monsterName in monsters"
          :key="monsterName"
          :monster-name="monsterName"
          @add="addToEncounter"
      />
    </div>
    <div class="opponents">
      <div>
        <strong>Total XP: </strong>{{ totalXp }}xp /
        <input class="num-players" v-model.number="numPlayers"/> Players =
        {{ numPlayers ? (totalXp / numPlayers) : 0 }}xp
      </div>
      <h2>Initiative</h2>
      <initiative-form @add-player="addPlayerToInitiative"/>
      <div v-for="e in initiative" :key="e.name">
        <strong>{{ e.name }}: </strong>{{ e.roll }}
      </div>

      <h2>Health</h2>
      <hitpoint-tracker
          v-for="(monster, i) in monstersInEncounter"
          :key="i"
          :monster-name="monster.name"
          :hit-dice="monster.hit_dice"
      />
    </div>
  </div>
</template>

<script>
import Monster from "@/components/Monster";
import Autocomplete from '@trevoreyre/autocomplete-vue';
import axios from "axios";
import HitpointTracker from "@/components/HitpointTracker";
import InitiativeForm from "@/components/InitiativeForm";

export default {
  name: "Encounter",
  components: {InitiativeForm, HitpointTracker, Monster, Autocomplete},
  data() {
    return {
      query: null,
      numPlayers: 0,
      monsters: [],
      monstersInEncounter: [],
      initiative: []
    }
  },
  computed: {
    totalXp() {
      return this.monstersInEncounter.reduce((prev, monster) => prev + monster.xp, 0);
    }
  },
  methods: {
    async searchMonsters(query) {
      const res = await axios.get('https://www.dnd5eapi.co/api/monsters?name=' + query);
      const monsters = res.data.results;
      return monsters.map(e => e.index);
    },
    addToMonsters(e) {
      const val = e.target.value;
      if (!this.monsters.includes(val)) {
        this.monsters.push(val);
      }
    },
    addToEncounter(monster) {
      this.monstersInEncounter.push(monster);
      this.addToInitiative(
        monster.name,
        this.rollForInitiative(monster)
      );
    },
    addPlayerToInitiative(name, roll) {
      if (this.addToInitiative(name, roll)) {
        this.numPlayers++;
      }
    },
    addToInitiative(name, roll) {
      if (this.initiative.filter(e => e.name === name).length > 0) {
        return false;
      }

      const player = {
        name: name,
        roll: roll
      };

      let item;
      let i = -1;
      do {
        i++;
        item = this.initiative[i];
      } while (item != null && roll <= item.roll);

      const firstSlice = this.initiative.slice(0, i);
      const secondSlice = this.initiative.slice(i);

      this.initiative = firstSlice;
      this.initiative.push(player);
      this.initiative = this.initiative.concat(secondSlice);

      return true;
    },
    rollForInitiative(monster) {
      let dexModifier = Math.floor(monster.dexterity / 2) - 5;

      let dexThrowProficiency = monster.proficiencies.filter(e => e.proficiency.index === 'saving-throw-dex')[0] || null;
      if (dexThrowProficiency != null) {
        dexModifier = dexThrowProficiency.value;
      }

      return Math.ceil(Math.random() * 20) + dexModifier;
    }
  }
}
</script>

<style>
  .encounter {
    display: flex;
  }

  .monsters {
    flex-basis: 65%;
  }

  .opponents {
    flex-basis: 30%;
    margin: 0 1rem;
  }

  .num-players {
    width: 2rem;
    border: none;
    border-bottom: 1px dotted black;
    font-size: 1rem;
  }

  .autocomplete {
    margin-bottom: 1rem;
  }

  .autocomplete input {
    width: 100%;
  }

  .autocomplete ul {
    list-style: none;
    background-color: white;

    margin: 0;
    border: 1px solid #aaa;
    padding: 0;
  }

  .autocomplete li {
    padding: 0.2rem;
  }

  .autocomplete li:hover, .autocomplete li[aria-selected] {
    background-color: #ccc;
  }
</style>