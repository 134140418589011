<template>
  <div :class="'hitpoint-tracker ' + (isDead ? 'dead' : null)">
    <strong>{{ monsterName }}:</strong>
    <span class="health">{{ health }}</span>
    <input v-model="healthModifier" :disabled="isDead" @keypress.enter="damage">
    <button v-if="!isDead" @click="heal">Heal</button>
    <button v-if="!isDead" @click="damage">Damage</button>
    <button v-if="isDead" @click="revive">Stabilize</button>
  </div>
</template>

<script>
export default {
  name: "HitpointTracker",
  props: {
    monsterName: String,
    hitDice: String
  },
  data() {
    return {
      healthModifier: null,
      health: this.getInitialHealth()
    }
  },
  computed: {
    isDead() {
      return this.health <= 0;
    }
  },
  methods: {
    getInitialHealth() {
      let dice, numDice, dieType, modifier;
      [dice, modifier] = this.hitDice.split('+');
      if (modifier === undefined) {
        [dice, modifier] = this.hitDice.split('-');
        modifier = modifier || 0;
        modifier = 0 - modifier;
      }

      [numDice, dieType] = dice.split('d');

      let total = modifier;
      for (let i = 0; i < numDice; i++) {
        total += Math.ceil(Math.random() * dieType);
      }

      return total;
    },
    heal() {
      let amount = Number(this.healthModifier);
      if (Number.isNaN(amount)) {
        return;
      }

      this.health += amount;
      this.healthModifier = null;
    },
    damage() {
      let amount = Number(this.healthModifier);
      if (Number.isNaN(amount)) {
        return;
      }

      this.health -= amount;
      this.healthModifier = null;
    },
    revive() {
      this.health = 1;
    }
  }
}
</script>

<style scoped>
  .hitpoint-tracker {
    display: flex;
    align-items: baseline;
  }

  .dead {
    color: red;
  }

  .health {
    flex: auto;
    margin: 0 0.25rem;
    min-width: 5rem;
  }

  input {
    flex: auto;
  }
</style>