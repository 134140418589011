<template>
  <div id="app">
    <encounter/>
  </div>
</template>

<script>
import Encounter from "@/components/Encounter";

export default {
  name: 'App',
  components: {
    Encounter,
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  color: #2c3e50;
  margin: 1rem;
}
</style>
