<template>
  <div class="initiative-form">
    <input v-model="playerName" placeholder="Name"/>
    <input v-model.number="roll" placeholder="Roll" @keypress.enter="submitForm"/>
  </div>
</template>

<script>
export default {
  name: "InitiativeForm",
  data() {
    return {
      playerName: null,
      roll: null
    }
  },
  methods: {
    submitForm() {
      let roll = Number(this.roll);
      if (Number.isNaN(roll)) {
        return;
      }

      console.log(this.roll);
      this.$emit('add-player', this.playerName, roll);
    }
  }
}
</script>

<style scoped>
  .initiative-form {
    display: flex;
  }
</style>